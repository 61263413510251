
.cyber-content {
    margin: 13px 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.create-btn {
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #2DC079;
    border-radius: 4px;
    color: #2DC079;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
}


.customTable {
    margin-top: 10px;
}
